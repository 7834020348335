import { Box, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import TaskCountCard from './TaskCountCard'
import TaskPriorityCard from './TaskPriorityCard'
import HoursSpentCard from './HoursSpentCard'
import { getMyDashboardData } from './api'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../../../../context/authContext'

const MyDashboard = () => {


    const { accessToken } = useContext(AuthContext);

    // console.log(accessToken)
    const { isSuccess, isLoading, isError, data: myDashboardData } = useQuery({
        queryKey: ['my_dashboard'],
        queryFn: () => getMyDashboardData(accessToken),
        // enabled: accessToken !== null
    })

    return (
        <Box m={2} mt={3}>
            <Typography variant="h5" color="secondary">
                My Dashboard
            </Typography>
            <Box mt={2}>
                <Stack direction="row" gap={1}>
                    <Stack gap={1}>
                        <TaskCountCard data={myDashboardData} />
                        <TaskPriorityCard />
                    </Stack>
                    <HoursSpentCard />
                </Stack>
            </Box>
        </Box>
    )
}

export default MyDashboard