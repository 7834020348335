import React, { useContext, useRef, useState } from 'react';
import {
    Avatar,
    Box,
    Container,
    Paper,
    TextField,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Typography,
    Dialog,
    DialogContent,
    InputBase,
    IconButton,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { AuthContext } from '../../../../../../context/authContext';
import { createComment, getComments } from './api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';

const commentsData = [
    { id: 1, user: 'User1', text: 'This is the first comment.' },
    { id: 2, user: 'User2', text: 'Another comment here.' },
    // Add more comments as needed
];

const CommentsDialog = ({ open, handleClose, task }) => {
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState(commentsData);
    const { accessToken } = useContext(AuthContext);
    const { accounts } = useMsal();
    const currentUser = accounts[0]?.name;
    const queryClient = useQueryClient()
    const [newAttachment, setNewAttachment] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);

    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: comments_data
    } = useQuery({
        queryKey: ['comments', task?.id],
        queryFn: () => getComments(accessToken, task?.id),
        // enabled: accessToken !== null && task?.id !== undefined,
    })

    const createCommentMutation = useMutation({
        mutationFn: (comment) => {
            return createComment({ comment, accessToken })
        },
        onSuccess: () => {
            queryClient.invalidateQueries('comments', task?.id)
        }
    })

    const handleAddComment = () => {
        if (newComment.trim() !== '') {
            // const newCommentObject = {
            //     user: currentUser,
            //     text: newComment.trim(),
            // };
            // const commentObject = {
            //     comment: newComment.trim(),
            //     taskId: task?.id,
            // }
            // setComments((prevComments) => [...prevComments, newCommentObject]);
            const formData = new FormData();
            formData.append('comment', newComment.trim());
            formData.append('taskId', task?.id);
            if (newAttachment) {
                formData.append('attachment', newAttachment);
            }

            console.log(newComment)
            createCommentMutation.mutate(formData)
            // await api.createComment(formData);
            setComments((prevComments) => [
                ...prevComments,
                { comment: newComment.trim() },
            ]);
            setNewComment('');
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setNewAttachment(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleAddAttachmentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} padding={2} fullWidth>
            <DialogContent>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Comments
                    </Typography>
                    <List>
                        {
                            comments_data?.length ?
                                comments_data?.map((comment, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>{comment?.user?.name.charAt(0)}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={comment?.user?.name || "Unknown"}
                                                secondary={
                                                    <>
                                                        {comment.comment}
                                                        {comment.attachment && (
                                                            <div>
                                                                <img
                                                                    src={`${process.env.REACT_APP_STATIC}/uploads/${comment.attachment}`}
                                                                    alt="attachment"
                                                                    style={{ maxWidth: '100%', marginTop: '10px' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))
                                :
                                <>
                                    Add a comment on the task
                                </>
                        }
                    </List>
                    <Box mt={2}>
                        {imagePreview && (
                            <Box mt={2}
                                sx={{
                                    display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center",
                                    backgroundColor: "#f2f2f24d", padding: "8px", borderRadius: "5px 5px 0px 0px"
                                }}
                            >
                                <Typography variant="body1">Image Preview:</Typography>
                                <img src={imagePreview} alt="Preview" style={{ maxWidth: '10%' }} />
                            </Box>
                        )}
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Add a comment"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                inputProps={{ 'aria-label': 'add comment' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="attachment" onClick={handleAddAttachmentClick}>
                                <AddPhotoAlternateIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton
                                color="primary"
                                sx={{ p: '10px' }}
                                onClick={handleAddComment}
                                aria-label="send">
                                <SendIcon />
                            </IconButton>
                        </Paper>
                        {/* Hidden element to atatch images. */}
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />

                        {/* <TextField
                            fullWidth
                            label="Add a comment"
                            variant="outlined"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ marginTop: '10px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddComment}
                            endIcon={<SendIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Add Comment
                        </Button> */}
                    </Box>




                    {/* <Box mt={2}>
                        <TextField
                            fullWidth
                            label="Add a comment"
                            variant="outlined"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddComment}
                            endIcon={<SendIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Add Comment
                        </Button>
                    </Box> */}
                </Paper>
            </DialogContent >
        </Dialog>
    );
};

export default CommentsDialog;
