import { Divider, Paper, Typography } from '@mui/material'
import React from 'react'

const ChartTemplate = ({ chart, title }) => {
    return (
        <Paper sx={{ width: "600px", height: "450px", bgcolor: "rgba(255,255,255,0.4)", p: 2, borderRadius: "10px" }}>
            <Typography variant='h6' fontWeight={500}>{title}</Typography>
            <Divider />
            {chart}
        </Paper>
    )
}

export default ChartTemplate