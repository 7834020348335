import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

//AuthHandlers

//--------Pages-------
// import Home3 from "../../pages/public/Home3";
import Home2 from "../../pages/public/Home/Home2";
import Error from "../../pages/public/Error";
import Navbar from "../../components/MainNavs/Navbar2";
import Footer from "../../components/Footer";
import SolutionPage from '../../pages/public/Solutions'

// import MyTasks from "../../pages/staff/MyTasks2";

//--------Admin-------
import { AuthProtected } from "../AuthProtectedOutlet";
import { AdminProtected } from "../AdminProtectedOutlet";
import SideNav from "../../components/MainNavs/SideNav";
import { mainRoutes } from "./mainRoutes";
import { adminProtectedRoutes } from "./adminProtectedRoutes";
import { AuthContext } from "../../context/authContext";
import PersistLogin from "../../components/RoutesHandler/PersistLogin";
import ProjectsPage from "../../pages/staff/ProjectsPage";
import paths from "../../api/paths.api";
import { authProtectedRoutes } from "./authProtectedRoutes";
import { useMsal } from "@azure/msal-react";
import NavWrapper from "../../components/NavWrapper";
// import { vendorRoutes } from "./vendorRoutes";

//--------Client--------

//--------Test--------

const Layout = () => {
  const { auth } = React.useContext(AuthContext);
  const { accounts } = useMsal();

  return (
    <>
      <PersistLogin>
        <NavWrapper />
        {/* <Navbar /> */}
        {/* {accounts?.length ? <SideNav /> : <></>} */}
        <Outlet />
      </PersistLogin>
    </>
  );
};

const roleLayout = () => {
  return (
    <>
      <PersistLogin>
        <Outlet />
      </PersistLogin>
    </>
  )
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      ...mainRoutes,
      {
        path: "",
        element: <AuthProtected />,
        children: [
          ...authProtectedRoutes,
          {
            path: "",
            element: <AdminProtected />,
            children: [...adminProtectedRoutes],
          },
        ],
      },
    ],
    errorElement: <Error error="404 page not found" />,
  },
  {
    path: "/home",
    // element: <Home3 />,
    element: <Home2 />,
  },
  {
    path: "/solutions",
    // element: <Home3 />,
    element: <SolutionPage />,
  },

]);
