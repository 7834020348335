import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMyTasks } from "./api";

//READ hook (get user tasks from api)
export function useGetMyTasks({ accessToken }) {
    // const { projectId } = useParams()
    return useQuery({
        queryKey: ['tasks'],
        queryFn: () => getMyTasks({ accessToken }),
        // enabled: accessToken !== null,
        keepPreviousData: true, // Keep the previous data while refetching
    });
}
