import axios from "../../../api/axios"

export const getAllTasks = async ({ accessToken }) => {
    const { data } = await axios.get("/task/all", {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data
}

export const getStatusDashboard = async ({ accessToken }) => {
    const { data } = await axios.get("/task/statusDashboard", {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data
}

export const getWorkloadDashboard = async ({ accessToken }) => {
    const { data } = await axios.get("/task/workloadDashboard", {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data
}