import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import Logo from "../../../images/chinook_blue-logo-only.png"
import { AccountCircle } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import paths from '../../../api/paths.api';

const drawerWidth = 210;

const settings = ['Logout'];

const navItems = [
    {
        label: "Projects",
        link: paths.myProjects,
    },
    {
        label: "Tasks",
        link: paths.allTasks,
    },
    {
        label: "Software",
        link: paths.software,
    },
]

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#F3F4F6FF",
    background: "linear-gradient(45deg, #f3f1fe 0%, #d9e7ff 100%)",
    color: "Black",
    boxShadow: "none",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }),
}));


const ResponsiveAppBar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { accounts } = useMsal();

    const { instance } = useMsal();
    const navigate = useNavigate()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const logoutAndRedirect = () => {
        instance.logoutRedirect()
        navigate("/");
    };

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar variant='dense' sx={{ display: 'flex', justifyContent: "space-between" }}>
                <Box display="flex">
                    <img src={Logo} alt="ChinookDT" style={{ height: "54px", width: "120px" }} />
                </Box>
                <Box sx={{ display: "flex", gap: "15px" }}>
                    {accounts?.length ? navItems.map((item, index) => (
                        <Button
                            key={index}
                            href={item.link}
                            // variant="text"
                            // startIcon={item?.icon}
                            // color:"#3f429a" 
                            sx={{ textTransform: 'none', fontSize: "0.875rem", }}>
                            {item.label}
                        </Button>
                    ))
                        :
                        <></>
                    }
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={logoutAndRedirect}>Logout</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default ResponsiveAppBar;