import axios from "../../../../../../api/axios"
import { tokenHeader } from "../../../../../../api/tokenHeader"

export const createComment = async ({ comment, accessToken }) => {
    return await axios.post("/task/comments", comment, tokenHeader(accessToken))
}

export const getComments = async (accessToken, taskId) => {
    const { data } = await axios.get("/task/comments/" + taskId, tokenHeader(accessToken))
    return data
}