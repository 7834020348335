import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SoftwareCard from './SoftwareCard'


import { createSoftware, downloadSoftware, getSoftwares } from './api';
import CreateDialog from './CreateDialog';
import { AuthContext } from '../../../context/authContext';
import ManageDialog from './ManageDialog.jsx';
import DownloadingCard from './DownloadingCard.jsx';
import axios from '../../../api/axios.js';

const SoftwarePage = () => {
    const [openDialog, setOpenDialog] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    const { auth } = useContext(AuthContext);
    const currentUser = auth;
    console.log(currentUser?.role)
    const [formData, setFormData] = useState({
        selectedFile: null,
        name: '',
        repository: "",
        description: ''
    });

    const [softwares, setSoftwares] = useState({})

    useEffect(() => {
        const fetchSoftwares = async () => {
            await getSoftwares()
                .then((res) => {
                    setSoftwares(res)
                })
        }
        fetchSoftwares()
    }, [])

    const handleFileChange = (event) => {
        setFormData({ ...formData, selectedFile: event.target.files[0] });
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleUpload = async () => {
        const { selectedFile, name, description, repository } = formData;
        const formDataObject = new FormData();
        formDataObject.append('image', selectedFile);
        formDataObject.append('name', name);
        formDataObject.append('description', description);
        formDataObject.append('repository', repository);

        try {
            await createSoftware(formDataObject)
            setOpenDialog(false)
            // console.log('Image uploaded:', response.data);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };


    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleDownload = async (software) => {
        console.log("ot Id", software)
        // await downloadSoftware(id)
        //     .then(response => {
        //         console.log("downloaded")
        //         console.log(response)
        //     })
        setIsDownloading(true);
        try {
            const response = await axios.get(`/software/download/${software.id}`, {
                responseType: 'blob',
            })
                .then(res => {
                    var blob = new Blob([res.data], { type: 'application/octet-stream' });
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    console.log("created a")
                    a.href = url;
                    a.download = `${software.name}.zip`;
                    a.click();
                    console.log("got response")
                    window.URL.revokeObjectURL(url);
                })

        } catch (error) {
            console.error('Error downloading software:', error);
            setIsDownloading(false);
        } finally {
            setIsDownloading(false);
        }
    }

    console.log({ isDownloading })
     
    const handleSaveRow = async (software) => {

    }
    const handleCreateRow = async (software) => {

    }

    return (
        <Box sx={{
            width: '100%', height: '100%',
            backgroundColor: "white", p: 4,
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
        }}>

            {/* Heading */}
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%', alignItems: "center", mb: 3 }}>
                <Typography variant='h4' sx={{ color: "#2E1164FF", fontWeight: 400 }}>Softwares</Typography>
                {
                    currentUser?.role === "owner" ?
                        <Button variant="outlined" size='small' onClick={() => setOpenDialog(true)}>
                            Manage
                        </Button>
                        : <></>
                }
            </Box>

            {
                isDownloading ?
                    <DownloadingCard />
                    :
                    <></>
            }

            {/* The Software Grid */}
            <Grid container spacing={2} direction="row">
                {
                    softwares?.length ?
                        softwares?.map((software, i) => (
                            <Grid key={i} item xs={3}>
                                <SoftwareCard
                                    title={software?.name}
                                    description={software?.description}
                                    imagePath={software?.imagePath}
                                    handleDownload={handleDownload}
                                    software={software}
                                />
                            </Grid>
                        ))
                        :
                        <></>
                }
            </Grid>

            {/* Dialog to manage software */}

            <CreateDialog
                openDialog={openDialog}
                formData={formData}
                handleClose={handleClose}
                handleChange={handleChange}
                handleUpload={handleUpload}
                handleFileChange={handleFileChange}
            />

            {/* TODO: Manage Software Dialog to Create/update */}

            {/* <ManageDialog
                openDialog={openDialog}
                handleClose={handleClose}
                data={softwares}
                handleSaveRow={handleSaveRow}
                handleCreateRow={handleCreateRow}
            /> */}

        </Box >

    )
}

export default SoftwarePage