import { useContext } from "react"
import axios from "../../../../api/axios"
import { tokenHeader } from "../../../../api/tokenHeader"

export const createProject = async ({ values, accessToken }) => {
    // console.log("proj, ", { ...values, revision: 0 })
    return await axios.post("/project", { ...values, revision: "0" }, tokenHeader(accessToken))
}

export const getAllProjects = async ({ accessToken }) => {
    const { data } = await axios.get("/project", tokenHeader(accessToken))
    return data
}


export const updateProject = async ({ values, accessToken }) => {
    return await axios.patch("/project/" + values.id, values, tokenHeader(accessToken))
}

export const deleteProject = async ({ projectId }) => {

}