import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import ProjectList from './ProjectList';
// import { getAllProjects } from './api';
import { useQuery } from "@tanstack/react-query"
import { Typography } from '@mui/material';
import Viewer from '../../../components/Viewer';
import axios, { secondAxios } from '../../../api/axios';
// import { ProjectContextProvider } from '../../../context/projectContext';

const ProjectsPage = () => {

    const [viewerModal, setViewerModal] = useState(false);
    const [documents, setSharepointDocuments] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [urn, setUrn] = useState();
    const [urnLoaded, setUrnLoaded] = useState(false);

    useEffect(() => {
        const getDocuments = async () => {
            const documentsData = (await axios.get("/sharepoint")).data;
            console.log(documentsData);

            const documents = documentsData.map((d) => ({
                name: d.name.split("-")[1],
                urn: d.urn,
            }));
            console.log("documentsss ", documents);

            setSharepointDocuments(documents);
            setUrnLoaded(true);
        };
        getDocuments();
    }, []);

    const handleClick = (row, tab) => {
        console.log('row', row.original.name);
        let prevUrn;
        prevUrn = documents.find((d) => d.name === "3d")?.urn;

        setUrn(prevUrn);
        // setSelectedProject(row.original.name);
        setViewerModal(true);
    };

    return (
        // <ProjectContextProvider>
        <Box sx={{ "display": "flex", alignItems: "start" }}>
            <Box sx={{ m: 4, width: "100%", pr: 3 }}>
                <Typography variant="h4" color="secondary">Projects</Typography>
                {/* <h2>Projects</h2> */}
                <ProjectList onClick={handleClick} />
            </Box>
            <Viewer
                open={viewerModal}
                handleClose={(event) => {
                    setViewerModal(false);
                }}
                urn={urn}
            // projectName={selectedProject}
            />
        </Box>
    )
}

export default ProjectsPage