import { Badge, Box, Button, Chip, IconButton, MenuItem } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { CircularProgressWithLabel } from "../../../components/widgets/CircularProgressWithLabel";
import { LinearProgressWithLabel } from "../../../components/widgets/LinearProgressWithLabel";

const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const convertDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Format the date
    // const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
    const formattedDate = `${(dateObject.getDate())}-${dateObject.toLocaleString('en-US', { month: 'short' })}-${dateObject.getFullYear()}`;
    return (
        <p>{formattedDate}</p>
    );
}

const statusColorMap = {
    'Not Started': 'default',
    'Progress': 'primary',
    'Hold': 'warning',
    'Completed': 'success',
    'Delayed': 'error',
};

export const allTaskListColumns = [
    {
        id: 'projectColumns',
        header: 'Project',
        columns: [
            {
                accessorKey: "id",
                header: `ID`,
                enableEditing: false
            },
            // {
            //     accessorKey: "project.createdAt",
            //     header: `Start`,
            //     size: 50,
            //     enableColumnActions: false,
            //     enableGrouping: false,
            //     Cell: ({ cell }) => (
            //         <>
            //             {
            //                 cell.getValue() ?
            //                     <div style={{ whiteSpace: 'wrap' }}>
            //                         {convertDateString(cell.getValue())}
            //                     </div>
            //                     : ""
            //             }
            //         </>
            //     )
            // },
            {
                accessorKey: "project.name",
                header: `Project`,
                size: 180,
            },
        ]
    },
    {
        id: 'taskColumns',
        header: 'Task',
        columns: [
            {
                accessorKey: "task",
                header: `Task`,
                size: 320,
                aggregationFn: 'count',
                AggregatedCell: ({ cell, table }) => (
                    <div>Number of Tasks: {cell.getValue()}</div>
                ),
            },
            {
                accessorKey: "task_members",
                header: `Responsible`,
                size: 100,
                Cell: ({ cell, row }) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {
                                cell.getValue() ?
                                    cell.getValue().map((user, index) => (
                                        <Chip key={index} label={user} size="small" />
                                    ))
                                    :
                                    ""
                            }
                        </Box>
                    )
                },
            },
            {
                accessorKey: "priority",
                header: 'Priority',
                size: 90,
            },
            {
                accessorKey: "createdAt",
                enableColumnActions: false,
                header: `Start`,
                size: 50,
                enableGrouping: false,
                Cell: ({ cell }) => (
                    <>
                        {
                            cell.getValue() ?
                                <div style={{ whiteSpace: 'wrap' }}>
                                    {convertDateString(cell.getValue())}
                                </div>
                                : ""
                        }
                    </>
                )
            },
            {
                accessorKey: "targetDate",
                header: `Due`,
                size: 50,
                enableGrouping: false,
                enableColumnActions: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => (
                    <>
                        {
                            cell.getValue() ?
                                <div style={{ whiteSpace: 'wrap' }}>
                                    {convertDateString(cell.getValue())}
                                </div>
                                : ""
                        }
                    </>
                )
            },
            {
                accessorKey: "completionDate",
                header: `End`,
                size: 50,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => (
                    <>
                        {
                            cell.getValue() ?
                                <div style={{ whiteSpace: 'wrap' }}>
                                    {convertDateString(cell.getValue())}
                                </div>
                                : ""
                        }
                    </>
                )
            },
            {
                accessorKey: "hours",
                header: 'Hours',
                size: 60,
                enableEditing: false,
                enableColumnFilter: false,
                enableGrouping: false,
                enableColumnActions: false,
                aggregationFn: 'sum',
                enableSorting: false,
                AggregatedCell: ({ cell, row, table }) => (
                    <>
                        {row.original?.timesheets?.reduce((acc, timesheet) => acc + parseFloat(timesheet?.hours), 0)}
                    </>
                ),
                Cell: ({ cell, row }) => {
                    const totalHours = row.original?.timesheets?.reduce((acc, timesheet) => acc + parseFloat(timesheet?.hours), 0)
                    return (
                        totalHours
                    )
                }
            },
            {
                accessorKey: "progress",
                header: 'Progress',
                size: 60,
                enableEditing: false,
                enableColumnFilter: false,
                enableGrouping: false,
                enableColumnActions: false,
                aggregationFn: 'mean',
                enableSorting: false,
                AggregatedCell: ({ cell, row, table }) => (
                    <>
                        {Math.round(row.original?.timesheets?.reduce((acc, timesheet) => acc + timesheet?.progress, 0))}%
                    </>
                ),
                Cell: ({ cell, row }) => {
                    const totalProgress = row.original?.timesheets?.reduce((acc, timesheet) => acc + timesheet?.progress, 0)
                    return (
                        <LinearProgressWithLabel value={totalProgress || 0} />
                    )
                }
            },
            {
                accessorKey: "status",
                header: `Status`,
                size: 90,
                Cell: ({ cell }) => (
                    <>
                        {
                            cell.getValue() ?
                                <Chip
                                    label={cell.getValue()}
                                    color={statusColorMap[cell.getValue()] || 'default'}
                                    size="small"
                                /> :
                                <></>
                        }
                    </>
                )
            }
        ]
    }

];
