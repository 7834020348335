import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'

const SkeletonBox = () => {
    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1
        }}>

            <Skeleton variant="circular" width={"100px"} height={"100px"} />
            <div>
                <Skeleton animation="wave" variant="rectangular" width={"150px"} height={"100px"} />
                {/* <Skeleton animation="wave" variant="rectangular" width={"100px"} height={40} /> */}
            </div>
            {/* <Skeleton animation="wave" variant="rectangular" width={210} height={40} /> */}
            {/* <Skeleton variant="rounded" width={210} height={60} /> */}

            {/* For variant="text", adjust the height via font-size */}
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}

        </Box>
    )
}

export default SkeletonBox