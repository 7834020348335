import axios from "../../../../../api/axios"

// export const createTask = async ({ task, projectId }) => {
export const createTask = async ({ task, projectId, accessToken }) => {
    // send task along with projectId(from URI / state)
    console.log("create", { ...task, projectId })
    return await axios.post("/task", { ...task, projectId }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })

}

export const getAllTasks = async ({ projectId, accessToken }) => {
    const { data } = await axios.get("/task?projectId=" + projectId, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data
}

export const getOneTask = async () => {
}

export const updateTask = async ({ task, projectId, accessToken }) => {
    console.log("update. ", task)
    return await axios.patch("/task/" + task?.id, { ...task, projectId }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })

}

export const deleteTask = async ({ taskId }) => {

}