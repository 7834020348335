// import MaterialReactTable from 'material-react-table'
import React, { useContext, useState, useEffect } from 'react'
import { columns } from './columns'
import { Box, DialogActions, DialogContent, DialogTitle, Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { minimalMuiTableProps, styledTableProps } from '../../../../styles/muiProps'
import paths from '../../../../api/paths.api'
import { useNavigate } from 'react-router-dom/dist'
import { MRT_EditActionButtons, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { AuthContext } from '../../../../context/authContext'
import ActionBar from '../../../../components/ActionBar'
import { createProject, updateProject } from './api'
import { categories, validateProject } from '../utils'
import { useCreateProject, useDeleteProject, useGetProjects, useUpdateProject } from './projectHooks'
import { EditableTable } from '../../../../components/Tables/EditableTable'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllUsernames, getAllUsers } from '../../../../api/usersApi'
import { useProjectContext } from '../../../../context/projectContext'

const data = [
    {
        name: "test",
        projectLeads: [],
        members: ["test", "Bill", "aysha", "sam", "Brutney"],
        tasks: { completed: 7, total: 10 }
    },
    {
        name: "test",
        projectLeads: [],
        members: [],
        tasks: {}
    },
]

const initialData = {
    name: "",
    projectLeads: [],
    members: [],
    tasks: [],
    revision: 0,
    type: ""
}

const ProjectList = ({ onClick }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const { accessToken, auth } = useContext(AuthContext);

    const currentUser = auth?.role;
    const navigate = useNavigate();
    const [filterType, setFilterType] = useState('');
    const [filteredProjects, setFilteredProjects] = useState(false);


    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: user_data
    } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsernames(accessToken),
        // enabled: currentUser === "admin" || currentUser === "owner",
    })

    console.log({ currentUser })
    const navigateToTaskList = (row) => {
        // get id of project here
        const id = row.id
        navigate(`${paths.myProjects}/${id}`)
    }
    const { data: projectsData, isLoading: projectLoading } = useGetProjects({ accessToken });

    useEffect(() => {
        if (!projectsData) return;

        console.log(projectsData);
        let projects = projectsData || [];
        if (filterType)
            projects = projects.filter(project => project.type === filterType)
        setFilteredProjects(projects);
    }, [filterType, projectsData]);

    const hooks = {
        useCreateItem: useCreateProject,
        useGetItems: useGetProjects,
        useUpdateItem: useUpdateProject,
        useDeleteItem: useDeleteProject
    };
    const actions = {
        validateItem: validateProject
    }

    const additionalProps = {
        ...minimalMuiTableProps,
        enablePagination: false,
    }

    return (
        <Box >
            {
                currentUser ?
                    <EditableTable
                        columns={columns(user_data || [], onClick)}
                        name="Project"
                        actions={actions}
                        hooks={hooks}
                        editMode="modal"
                        enableEditing={true}
                        enableCreate={true}
                        // enableEditing={currentUser === "admin" || currentUser === "owner" ? true : false}
                        // enableCreate={currentUser === "admin" || currentUser === "owner" ? true : false}
                        additionalProps={additionalProps}
                        initialData={initialData}
                        data={filteredProjects}
                    />
                    :
                    <>
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} height={60} />

                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton animation="wave" variant="rectangular" height={"50vh"} />
                    </>
            }
        </Box>
    )
}

export default ProjectList