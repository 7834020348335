import paths from "../../api/paths.api.js";
import ProjectsPage from "../../pages/staff/ProjectsPage/index.jsx";
import ProjectTasksPage from "../../pages/staff/ProjectsPage/ProjectTasksPage/index.jsx";
// import MyTasks from "../../pages/staff/MyTasks2";
import ComingSoonPage from "../../components/ComingSoonPage/index.js";
import MyTasksPage from "../../pages/staff/MyTasksPage/index.jsx";
import MePage from "../../pages/staff/MePage.jsx/index.jsx";
import AllTasksPage from "../../pages/admin/AllTasksPage/index.js";
import SoftwarePage from "../../pages/admin/SoftwarePage/index.jsx";

export const authProtectedRoutes = [
    {
        path: paths.mePage,
        // element: <ComingSoonPage />,
        element: <MePage />,
    },
    {
        path: paths.myTasks,
        // element: <ComingSoonPage />,
        element: <MyTasksPage />,
    },
    {
        path: paths.myProjects,
        element: <ProjectsPage />,
    },
    {
        path: paths.singleProject,
        element: <ProjectTasksPage />,
    },
    {
        path: paths.allTasks,
        element: <AllTasksPage />,
    },
    {
        path: paths.software,
        element: <SoftwarePage />,
    },
]