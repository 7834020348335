import axios from "../../../../../../api/axios"

// export const createTimesheet = async ({ timesheet, taskId }) => {
export const createTimesheet = async ({ timesheet, taskId, accessToken }) => {
    // send timesheet along with taskId(from URI / state)
    console.log("SEd", { ...timesheet, taskId })
    return await axios.post("/timesheet", {
        ...timesheet,
        hours: parseFloat(timesheet.hours),
        progress: parseFloat(timesheet.progress),
        taskId
    },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
}

export const getAllTimesheets = async ({ taskId, accessToken }) => {
    const { data } = await axios.get("/task/" + taskId, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
    return data?.timesheets
}
export const getOneTimesheet = async () => {
}

export const updateTimesheet = async ({ timesheet, taskId, accessToken }) => {
    console.log("tome", { timesheet, taskId })
    return await axios.patch("/timesheet/" + taskId, { timesheet, taskId }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })

}

export const deleteTimesheet = async ({ timesheetId, accessToken }) => {
    console.log({ timesheetId, accessToken }, "to be deleted")
    return await axios.delete(`/timesheet/${timesheetId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
}