// import Main2 from "../../pages/client/AllEquipments/PressureVessel";
import ManageStaff from "../../pages/admin/ManageStaff";

import AllTasksPage from "../../pages/admin/AllTasksPage";
import paths from "../../api/paths.api";
import AllTimesheetsPage from "../../pages/admin/AllTimesheetsPage";

export const adminProtectedRoutes = [

  {
    path: "/allstaff",
    element: <ManageStaff />,
  },
  // {
  //   path: paths.allTasks,
  //   element: <AllTasksPage />,
  // },
  {
    path: paths.allTimesheets,
    element: <AllTimesheetsPage />,
  },

];
