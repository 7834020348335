import { Box, Stack, Typography } from "@mui/material";

export const timesheetColumns = (username, validationErrors, setValidationErrors) => [
    {
        accessorKey: "id",
        header: "key",
        size: 50,
        enableEditing: false,
    },
    {
        accessorKey: "date",
        header: `Date`,
        size: 60,
        muiEditTextFieldProps: {
            type: "date",
            error: !!validationErrors.date, //highlight mui text field red error color
            helperText: validationErrors.date,
            onChange: (event) => {
                const value = event.target.value;
                //validation logic
                if (!value) {
                    setValidationErrors((prev) => ({ ...prev, date: 'Date is required' }));
                }
                else {
                    delete validationErrors.date;
                    setValidationErrors({ ...validationErrors });
                }
            }
        },
        enableEditing: row => {
            let task_member = row.original.task_member
            return (task_member === username || !task_member ? true : false)
        },
        Footer: () => (
            <Typography color="warning.main" sx={{ pl: 2, fontWeight: "bold" }}>Total</Typography>
        )

    },
    {
        accessorKey: "workDone",
        header: `Work Done`,
        enableEditing: row => {
            const task_member = row.original.task_member
            return (task_member === username || !task_member ? true : false)
        },
        size: 300,
    },
    {
        accessorKey: "hours",
        header: `Hours`,
        size: 60,
        muiEditTextFieldProps: {
            type: "number",
        },
        enableEditing: row => {
            const task_member = row.original.task_member
            return (task_member === username || !task_member ? true : false)
        },
        Footer: ({ table }) => {
            const totalHours = table.getRowModel().rows.reduce((total, row) => {
                return total + parseFloat(row.original.hours || 0);
            }, 0);

            return (
                <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalHours}</Typography>
                // <Typography>Hours: {totalHours}</Typography>
            );
        }
    },
    {
        accessorKey: "progress",
        header: `Progress`,
        size: 60,
        enableEditing: row => {
            const task_member = row.original.task_member
            return (task_member === username || !task_member ? true : false)

            // return (task_member === username || task_member === "" ? true : false)
        },
        muiEditTextFieldProps: {
            type: "number",
        },
        Footer: ({ table }) => {
            const totalProgress = table.getRowModel().rows.reduce((total, row) => {
                return total + parseFloat(row.original.progress || 0);
            }, 0);

            return (
                <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalProgress}%</Typography>
                // <Typography>Progress: {totalProgress}%</Typography>
            );
        }
    },
    {
        accessorKey: "task_member",
        header: `By`,
        size: 60,
        enableEditing: false
    },
]