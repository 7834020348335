import React, { useContext } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import ChartTemplate from './ChartTemplate';


const HorizontalStackedChart = ({ xData, yData, title, colors }) => {

    console.log(xData, title, colors, yData)
    const formattedData = xData ? Object.entries(xData).map(([item, counts]) => ({
        data: counts,
        label: item,
        id: `${item}Id`,
        stack: 'total'
    })) : []

    const nameFormater = (name) => name.length > 15 ? `${name.slice(0, 16)}...` : name;

    const chart = <BarChart
        margin={{ left: 120 }}
        layout="horizontal"
        series={formattedData}
        colors={colors}
        yAxis={[{ data: yData, scaleType: 'band', valueFormatter: nameFormater }]}
        slotProps={{
            legend: {
                labelStyle: {
                    fontSize: "12px"
                },
            },
        }}
    />

    return (
        <ChartTemplate chart={chart} title={title} />
    )
}

export default HorizontalStackedChart