import React from 'react'
import MyDashboard from './MyDashboard.jsx/index.jsx'
import { Box } from '@mui/material'

const MePage = () => {
    return (
        <Box>
            <Box sx={{ width: "806px", height: "402px", m: 2 }}>
                <MyDashboard />
            </Box>
        </Box>
    )
}

export default MePage