import React, { useEffect, useState, useContext } from 'react'
import axios from '../../api/axios'
import { AuthContext } from '../../context/authContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PersistLogin = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    const { auth, setAuth, accessToken } = useContext(AuthContext)
    useEffect(() => {
        const verifyAccessToken = async () => {
            if (accessToken)
                await axios.get("/auth/verifyToken", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then((response) => {
                        // console.log("VERIFIED", response)
                        setIsLoading(false)
                        const role = response.data.role
                        setAuth({ role })
                    })
                    .catch((err) => {
                        console.log("NOT VERIFIED")
                        setIsLoading(false)
                    })
            else {

            }
        }

        !auth?.role ? verifyAccessToken() : setIsLoading(false)
        if (!accessToken)
            setIsLoading(false)
    }, [accessToken])

    return (
        isLoading ?
            <Box>
                <CircularProgress />
            </Box>
            :
            <Box sx={{ display: 'flex', flexDirection: "column" }}>
                {children}
            </Box>
        // children
    )
}

export default PersistLogin