import { Avatar, AvatarGroup, Box, Chip, Link, MenuItem, Tooltip } from "@mui/material";
import { stringAvatar } from "../components/StringAvatar";
import { LinearProgressWithLabel } from "../../../../components/widgets/LinearProgressWithLabel";
import paths from "../../../../api/paths.api";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { convertDateString } from "../../../../utils/convertDateString";
import { categories } from "../utils";

import sp_icon from "../../../../images/sp_icon.png";
import autodesk_icon from "../../../../images/autodesk.png";
import TaskOutlinedIcon from '@mui/icons-material/Task';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const columns = (users, onClick) => [
    {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
        Edit: () => (
            <></>
        ),
    },
    // {
    //     accessorKey: 'createdAt',
    //     header: 'Start',
    //     enableEditing: false,
    //     size: 80,
    //     Cell: ({ cell }) => (
    //         <>
    //             {
    //                 cell.getValue() ?
    //                     <div style={{ whiteSpace: 'wrap' }}>
    //                         {convertDateString(cell.getValue())}
    //                     </div>
    //                     : ""
    //             }
    //         </>
    //     ),
    //     Edit: () => (
    //         <></>
    //     ),
    // },
    {
        accessorKey: "name",
        header: "Project Name",
        size: 500,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
            required: true,
        }),
    },
    // {
    //     accessorKey: "type",
    //     header: "Project Type",
    //     size: 80,
    //     muiEditTextFieldProps: ({ cell, row, table }) => ({
    //         required: false,
    //         select: true,
    //         children: categories.map((category, i) =>
    //             <MenuItem key={i} value={category}>
    //                 {category}
    //             </MenuItem>
    //         )
    //     }),
    // },
    {
        accessorKey: "users_projects",
        header: "Members",
        size: 150,
        Cell: ({ row, cell }) => {
            const members = cell.getValue();
            const visibleMembers = members?.slice(0, 2); // Show only the first 2 members

            return (
                <>
                    {visibleMembers?.map((member, index) => (
                        <Chip key={index} label={member} size="small" />
                    ))}
                    {members?.length > 2 && (
                        <Tooltip title={members.slice(2).join(', ')} arrow>
                            <Chip label={`${members.length - 2}+`} size="small" />
                        </Tooltip>
                    )}
                </>
            );
        },
        muiEditTextFieldProps: ({ value, onChange }) => ({
            children: users.map((user, index) => (
                <MenuItem key={index} value={user}>
                    {user}
                </MenuItem>
            )),
            select: true,
            SelectProps: {
                multiple: true,
                renderValue: (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, index) => (
                            <Chip key={index} label={value} />
                        ))}
                    </Box>
                )

            }
        }),
    },
    {
        accessorKey: "tasks",
        header: "Tasks",
        size: 40,
        enableEditing: false,
        enableFiltering: false,
        enableColumnActions: false,
        Edit: () => null,
        Cell: ({ row, cell }) => {
            return (
                <a href={`/projects/${row.original.id}`}>
                    <TaskOutlinedIcon color="primary" />
                </a>
            )
        }
    },
    {
        accessorKey: 'document',
        header: 'DCS',
        size: 40,
        enableEditing: false,
        enableColumnActions: false,
        Edit: () => null,
        Cell: ({ cell, row }) => {
            const name = row.original.name;

            // if(name)
            const link = "https://enerfacprojectscom.sharepoint.com/sites/AndritzProject/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FAndritzProject/Shared%20Documents"
                +"/Pressure Vessels"


            return (
                <a href={link} target="_blank">
                    <img src={sp_icon} height={'40px'} alt="SharePoint" />
                </a>
            );
        },
    },

    // {
    //     accessorKey: 'model',
    //     header: '3D',
    //     size: 40,
    //     enableEditing: false,
    //     Edit: () => null,
    //     enableColumnActions: false,
    //     Cell: ({ cell, row }) => {
    //         return (
    //             <a
    //                 // target="_blank"
    //                 style={{ cursor: 'pointer' }}
    //                 onClick={() => {
    //                     onClick(row);
    //                 }}
    //             >
    //                 <img src={autodesk_icon} height={'30px'} alt="SharePoint" />
    //             </a>
    //         );
    //     },
    // },
]