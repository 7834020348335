import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createUser, deleteUser, getAllUsers, updateUser } from "./api";

//CREATE hook (post new user to api)
export function useCreateUser() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({values, accessToken}) => createUser({values, accessToken}),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    });
}


//READ hook (get users from api)
export function useGetUsers({ accessToken }) {
    return useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers({ accessToken }),
        // enabled: accessToken !== null
    });
}

//UPDATE hook (put user in api)
export function useUpdateUser() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({values, accessToken}) => updateUser({values, accessToken}),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    });
}

//DELETE hook (delete user in api)
export function useDeleteUser() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUser,
        onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    });
}