import { Navigate } from "react-router-dom";

import Contact from "../../pages/public/Contact";

//--------Staff-------
import StaffLogin from "../../pages/staff/auth/StaffLogin";

export const mainRoutes = [
  {
    index: true,
    element: <Navigate to="/home" />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/dev/login",
    element: <StaffLogin />,
  },  
];
