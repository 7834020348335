const paths = {
  login: "/dev/login",
  mePage: "/dev/me",
  myProjects: "/projects",
  singleProject: "/projects/:projectId",
  myTasks: "/mytasks",
  // projectFormApi: "/new-project",
  projectEquipsApi: "/project-equipments",
  EquipmentsApi: "equipments",
  vendorScreen: "/vendor",
  vendorMrqs: "/vendor-mrqs/:token",
  clientVendors: "/client-vendors-page",
  materialsDB: "/materials-database",
  scm: "/supply-chain-management",
  allstaff: "/allstaff",
  main_pv: "/projects/:id/equipments/main_pv/:equipId",
  main_pv_tabs: "/projects/:id/equipments/main_pv/:equipId/*",

  // admin
  allTasks: "/admin/alltasks",
  allTimesheets: "/admin/allTimesheets",

  software: "/softwares"
};

export default paths;
